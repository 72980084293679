import { ReactNode } from 'react';
import { AccordionTestIds } from 'constants/testsIds/rankingV2Page';
import * as S from './AccordionStyles';

export interface AccordionProps {
  title: string;
  isExpanded: boolean;
  children: ReactNode;
  onClick: (index: number) => void;
  index: number;
  className?: string;
  dataCy?: AccordionTestIds;
}

const Accordion = (props: AccordionProps) => {
  const { title, isExpanded = false, children, onClick, index = 0, className = '', dataCy } = props;
  const uniq = `id_${new Date().getTime().toString()}`;

  return (
    <S.Wrapper className='accordion-wrapper'>
      <S.Toggle
        type='button'
        aria-expanded={isExpanded}
        open={isExpanded}
        aria-controls={`panel-${uniq}`}
        id={`accordion-${uniq}`}
        onClick={() => onClick(index)}
        className={`${className} button`}
        data-cy={dataCy?.TOGGLE_BUTTON}
      >
        <S.Title
          className={`${className} title`}
          data-cy={dataCy?.TITLE}
        >
          {title}
        </S.Title>
        <S.Arrow
          className='arrow'
          aria-hidden='true'
          data-cy={dataCy?.ARROW}
        />
      </S.Toggle>
      <S.Content
        id={`panel-${uniq}`}
        role='region'
        hidden={!isExpanded}
        aria-labelledby={`accordion-${uniq}`}
        className={`${className} content`}
        data-cy={dataCy?.CONTENT}
      >
        {children}
      </S.Content>
    </S.Wrapper>
  );
};

export default Accordion;
