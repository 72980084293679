import Footer, { FooterProps } from 'components/Footer';
import { HeaderNav } from 'interfaces/navigation/Header';
import Header from 'components/Header';
import { LayoutWrapper, LeaderboardAdWrapper, NativoLeaderboard } from 'components/Layout/LayoutStyles';
import { Leaderboard } from 'components/Ad/AdSlot';
import { PageType } from 'interfaces/content/articles/Post';
import RecommendsFooter, { RecommendsFooterProps } from 'components/RecommendsFooter';
import { Microsites } from 'constants/constants';
import { Hub } from 'interfaces/Home';

// import InhouseAd from 'components/Ad/InhouseAd';

interface LayoutProps {
  children: JSX.Element;
  headerNav: HeaderNav;
  leaderboard: boolean;
  recommendsFooter?: RecommendsFooterProps;
  footer?: FooterProps;
  hasVanityUrl: boolean | undefined;
  pageType: PageType;
  currentSection: string;
  section?: Hub;
  currentTags: string[];
  currentAuthors?: string;
}

const Layout = (props: LayoutProps) => {
  const {
    children,
    leaderboard,
    recommendsFooter,
    footer,
    headerNav,
    hasVanityUrl,
    section,
    // pageType,
    // currentTags,
    // currentAuthors,
    // currentSection,
  } = props;

  const isRecommends = [Microsites.Recommends, Microsites.Education].includes(headerNav?.subDomain);

  return (
    <>
      {leaderboard && (
        <LeaderboardAdWrapper>
          <Leaderboard id={0} />
        </LeaderboardAdWrapper>
      )}
      {hasVanityUrl && <NativoLeaderboard className='nativo-content__leaderboard' />}
      {headerNav && (
        <Header
          {...headerNav}
          topics={headerNav.topicNavigation}
        />
      )}
      <LayoutWrapper $isRecommends={isRecommends}>
        <main>{children}</main>
        {!isRecommends && footer && <Footer {...footer} />}
        {isRecommends && recommendsFooter && (
          <RecommendsFooter
            {...recommendsFooter}
            currentSection={section}
          />
        )}
      </LayoutWrapper>
      {/* Sticky Ads (old Digioh 3rd party) are on hold for now  */}
      {/* <InhouseAd */}
      {/*   pageType={pageType} */}
      {/*   placementName='Sticky' */}
      {/*   currentSection={currentSection || ''} */}
      {/*   currentTags={currentTags?.join(',') || ''} */}
      {/*   currentAuthors={currentAuthors || ''} */}
      {/* /> */}
    </>
  );
};

export default Layout;
