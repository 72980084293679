const WIDGETS_TEST_IDS = {
  CONTENT_HIGHLIGHT_CARD_WIDGET: 'content-highlight-card-widget',
  EXPLORE_OUR_PRODUCTS_WIDGET: 'explore-our-products-widget',
  MEET_OUR_TEAM_PROFILE_CARD: 'meet-our-team-profile-card',
  MEET_OUR_TEAM_WIDGET: 'meet-our-team-widget',
  MEET_OUR_TEAM_WIDGET_ARROW_NEXT: 'meet-our-team-widget-arrow-next',
  MEET_OUR_TEAM_WIDGET_ARROW_PREV: 'meet-our-team-widget-arrow-prev',
  MEET_OUR_TEAM_WIDGET_TITLE: 'meet-our-team-widget-title',
  MONTHLY_ARTICLES_WIDGET: 'monthly-articles-widget',
  TOP_HIGHLIGHT_CARD_WIDGET: 'top-highlight-card-widget',
};

export default WIDGETS_TEST_IDS;
