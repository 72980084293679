import { blueSecondaryLink } from 'components/Globals/Base/Link/LinkStyles';
import styled from 'styled-components';
import { breakpoint, color, font } from 'styles/globals';
import { Theme } from 'styles/theme';
import { ctaCaption } from 'styles/paragraphs';

export const ResponsiveTableWrapper = styled.div`
  width: 100%;
  margin: 24px 0;
`;

const ResponsiveTable = styled.table<{ $colNumber?: number; theme: Theme }>`
  border-collapse: collapse;
  font-family: ${font.graphikCond};
  text-align: left;
  width: 100%;

  mark {
    display: block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: ${color.recommendsDarkGreen};

    & + br {
      display: none;
    }
  }

  ul, ol {
    margin: 0;
    }
    
  .last-cell {
    & > a:first-child {
      ${blueSecondaryLink};
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 600;
      font-size: 16px;
      text-wrap: nowrap;

      &:not([href*="fortune.com"]):not([href^="#"]):after,
      &[href*="fortune.com/recommends/redirect"]:after {
        content: url("/icons/OpenInNewTab.svg");
        height: 19px;
        width: 19px;
      }
    }
      
    &:has(a:first-child) {
      ${ctaCaption};
      text-align: left;
      text-wrap: nowrap;
      line-height: 75%;
    }
  }
`;

export const ResponsiveTableDesktop = styled(ResponsiveTable)`
  display: none;

  tr {
    font-size: 16px;
  }

  th, td {
    padding: 16px 16px;
    position: relative;

    &:nth-child(1) {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }

    &:not(:last-child):after {
        content:"";
        border-right: 1px solid ${color.secondaryLightGray};
        position: absolute;
        top: 8px;
        bottom: 8px;
        right: 0px;
    }
  }

  tbody {
    th {
      font-weight: 400;
    }

    tr:not(:last-child) {
      border-bottom: 2px solid ${color.secondaryLightGray};
      
      &:has(+ tr mark) {
        border-bottom: none;
      }

      &:has(mark) {
        background-color: ${color.lightBlue};
        border: 2px solid ${color.linkBlue};
      } 
    }

    tr:nth-child(1) {
      border-top: 2px solid ${color.recommendsDarkGreen};
      &:has(mark) {
        background-color: ${color.lightBlue};
        border: 2px solid ${color.linkBlue};
      }
    }

    & > tr:nth-of-type(2n) {
      background-color: ${color.lightGray};
    }
  }

  @media (${breakpoint.mdMin}) {
    display: table;
  }
`;

export const ResponsiveTableMobile = styled(ResponsiveTable)`
  display: table;

  thead {
    border-bottom: 2px solid ${color.recommendsDarkGreen};
    
    tr > th {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0.5px;
      padding: 8px 0;
    }
  }
    
  tbody {
    &:before {
      content: "@";
      display: block;
      line-height: 24px;
      visibility: hidden;
    }
    
    th, td {
      padding: 8px;
    }

    th.header {
      font-weight: 400;
    }

    & > tr {
      font-size: 14px;

      &:not(:last-child) {
        border-bottom: 1px solid ${color.secondaryLightGray};
      } 
    }
  
    & > tr:nth-of-type(${({ $colNumber }) => ($colNumber as number) - 1}n+1) {
      background-color: ${color.lightGray};
      border-bottom: none;
      font-size: 16px;
      letter-spacing: 0.5px;

      &:has(mark) {
        background-color: ${color.lightBlue};
        border-bottom: 2px solid ${color.linkBlue};
      }
    }
  }

  @media (${breakpoint.mdMin}) {
    display: none;
  }
`;
