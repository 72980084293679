import React, { SetStateAction, createContext, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import Script from 'next/script';
import { PianoPageConfig } from 'services/Piano/entities/PianoPageConfig';
import { pianoInit, defaultSubscriberContext, pianoMyAccount } from 'services/Piano';
import { Subscriber, validatePianoCookie } from 'services/Subscriber';
import { IterablePageData } from 'services/Iterable/entities';
import IterableTracker from 'services/Iterable';
import { useRouter } from 'next/router';
import { subscriberPush, userPush } from 'services/Gtm/functions';
import { BypassPaywall } from 'interfaces/BypassPaywall';
import CookieService from 'utils/miscUtils/CookieService';
import CustomWindow from 'interfaces/Utils';
import { changeRoute } from 'components/Globals/Base/Link';

declare let window: CustomWindow;
interface UserProviderProps {
  children: JSX.Element[];
  pianoPageConfig: PianoPageConfig;
  iterablePageViewData: IterablePageData;
  bypassPaywall?: BypassPaywall;
}
interface UserContextInterface {
  hasLoggedIn: boolean;
  isBypassed: boolean;
  logOut: () => void;
  paywall30: string;
  setUserState: React.Dispatch<SetStateAction<typeof defaultSubscriberContext>>;
  userState: typeof defaultSubscriberContext;
  hasConsent: boolean | null;
  setHasConsent: React.Dispatch<SetStateAction<boolean | null>>;
}

const defaultSetUserState = (() => {}) as React.Dispatch<SetStateAction<typeof defaultSubscriberContext>>;
const defaultSetHasConsent = (() => {}) as React.Dispatch<SetStateAction<boolean | null>>;

export const UserContext = createContext({
  hasConsent: null,
  hasLoggedIn: false,
  isBypassed: false,
  logOut: () => {},
  paywall30: 'pending',
  setHasConsent: defaultSetHasConsent,
  setUserState: defaultSetUserState,
  userState: defaultSubscriberContext,
}) as React.Context<UserContextInterface>;

export const UserProvider: React.FC<UserProviderProps> = ({
  children,
  pianoPageConfig,
  iterablePageViewData,
  bypassPaywall,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [initialized, setInitalized] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [isBypassed, setIsBypassed] = useState(false);
  const [userState, setUserState] = useState(defaultSubscriberContext);
  const [paywall30, setPaywall30] = useState('pending');
  const router = useRouter();
  const allowBypass = useRef(false);
  const [hasConsent, setHasConsent] = useState<boolean | null>(null);

  useEffect(() => {
    if (userState.isLoggedIn === false && router.pathname.includes('my-account')) {
      if (router.pathname.includes('my-account/newsletters')) {
        // eslint-disable-next-line no-void
        void changeRoute('/newsletters/');
      } else {
        // eslint-disable-next-line no-void
        void changeRoute('/');
      }
    }
  }, [userState.isLoggedIn, router.pathname]);

  useEffect(() => {
    setHasLoggedIn(validatePianoCookie());
  }, []);

  useEffect(() => {
    if (bypassPaywall && bypassPaywall.postBypassLinkEnabled) {
      allowBypass.current = Object.keys(router.query).some((query) => query === bypassPaywall.postBypassLinkUnique);
    }
    setIsBypassed(allowBypass.current);
  }, [router.isReady, router.query, bypassPaywall]);

  // Remove paywall anchor element in case experience executes before router params are ready
  useEffect(() => {
    if (isBypassed && paywall30 === 'paywalled') {
      const paywallAnchor = document.getElementsByClassName('paywall-selector')[0];
      paywallAnchor?.remove();
    }
  }, [isBypassed, paywall30]);

  const handleUserChange = useCallback(
    (refreshExperience = true) => {
      const initSubscriber = async () => {
        const subscriber = new Subscriber();
        await subscriber.init();

        return subscriber;
      };

      initSubscriber()
        .then((subscriber) => {
          if (subscriber.email) {
            subscriber.iterableTracker = new IterableTracker(subscriber.email); // eslint-disable-line no-param-reassign
          }
          subscriberPush(subscriber);
          userPush(!!subscriber.isLoggedIn);
          setUserState(subscriber);
          setInitalized(true);
          if (refreshExperience) {
            window.tp.experience.execute();
          }
        })
        .catch((error) => console.error(error));
    },
    [router.pathname],
  );

  const logOut = useCallback(() => {
    const domain = window.location.hostname;
    CookieService.remove('uatr', {});
    CookieService.remove('uat', {});
    CookieService.remove('__utp', { domain });
    CookieService.remove('pianoUid', {});
    window.tp.pianoId.logout(handleUserChange);
  }, [handleUserChange]);

  useEffect(() => {
    if (loaded) {
      pianoInit(pianoPageConfig, isBypassed, setPaywall30, handleUserChange);
    }
  }, [isBypassed, loaded, handleUserChange, pianoPageConfig]);

  useEffect(() => {
    if (loaded && !initialized) {
      handleUserChange(false);
      if (router.pathname.includes('/my-account/payment')) pianoMyAccount();
    }
  }, [initialized, loaded, handleUserChange, router.pathname]);

  useEffect(() => {
    if (userState.iterableTracker) {
      iterablePageViewData.url = window.location.href; // eslint-disable-line no-param-reassign
      userState.iterableTracker.trackPageView(iterablePageViewData).catch((error) => console.error(error));
    }
  }, [iterablePageViewData, userState]);

  const memoizedUserState = useMemo(
    () => ({ hasConsent, hasLoggedIn, isBypassed, logOut, paywall30, setHasConsent, setUserState, userState }),
    [hasLoggedIn, isBypassed, logOut, paywall30, userState, setUserState, hasConsent, setHasConsent],
  );

  return (
    <>
      <Script
        src={process.env.PIANO_CDN_URL}
        id='piano-id'
        strategy='afterInteractive'
        onLoad={() => setLoaded(true)}
      />
      <UserContext.Provider value={memoizedUserState}>{children}</UserContext.Provider>
    </>
  );
};
