const nFormatter = (num: number): string => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return String(num);
};

const existingAdIds: Set<number> = new Set();

export const generateUniqueId = (): number => {
  const createId = (): number => Math.floor(100 + Math.random() * 901);
  let newId: number;
  do {
    newId = createId();
  } while (existingAdIds.has(newId));

  existingAdIds.add(newId);
  return newId;
};

export default nFormatter;
