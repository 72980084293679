/* eslint-disable camelcase */
import IterableTracker from 'services/Iterable';
import sha256 from 'crypto-js/sha256';
import geoIp from 'paymentApi/geoIp';
import { trackEvent } from 'services/Gtm/functions';
import CustomWindow from 'interfaces/Utils';
import { Tinypass } from 'services/Piano/entities/Tinypass';
import { Subscriber } from 'services/Subscriber';
import CookieService from 'utils/miscUtils/CookieService';
import {
  AccessResponse,
  ConversionResponse,
  CheckoutCustomEvent,
  CustomEvent,
  ExperienceEvent,
  LoginDataProps,
  OfferTerms,
  ResponseVariables,
  Term,
  TermDetailsProps,
  OfferParams,
} from './entities/PianoCallbackParams';

declare let window: CustomWindow;

interface SessionData {
  beganSignup: boolean | undefined;
  checkoutStarted: boolean | undefined;
  currentTerm: Term | undefined;
  identifiers: {
    fuid: string | undefined;
    ga_client_id: string | undefined;
    piano_user_id: string | undefined;
  };
  offerTerms: OfferTerms | undefined;
  termUnset: boolean;
}

const sessionData: SessionData = {
  beganSignup: undefined,
  checkoutStarted: undefined,
  currentTerm: undefined,
  identifiers: {
    fuid: undefined,
    ga_client_id: undefined,
    piano_user_id: undefined,
  },
  offerTerms: undefined,
  termUnset: true,
};

const unsetTermProps = {
  currency: 'Currency unset',
  description: 'Description unset',
  name: 'Name unset',
  price: 'Price unset',
};

const pianoHandlers = (
  tp: Tinypass,
  setPaywall30: React.Dispatch<React.SetStateAction<string>>,
  handleUserChange: (refreshExperience?: boolean) => void,
) => ({
  checkoutClose: (event: CheckoutCustomEvent) => {
    const { analytics } = window;
    switch (event.state) {
      case 'close':
        analytics?.track('form abandoned', {
          form_name: 'Paywall',
        });
        break;
      default:
        break;
    }
    window.tp.experience.execute();
  },
  checkoutComplete: async (conversion: ConversionResponse) => {
    const { analytics } = window;
    const { chargeAmount, chargeCurrency, paymentId, termId } = conversion;
    const { name, description } = sessionData.currentTerm as Term;

    const orderCompletedPayload = {
      ...sessionData.identifiers,
      currency: chargeCurrency,
      products: sessionData.termUnset ?
        [
          {
            currency: chargeCurrency,
            description,
            id: termId,
            name,
            price: chargeAmount,
          },
        ] :
        [sessionData.currentTerm],
      transaction_id: paymentId,
      value: chargeAmount,
    };

    analytics?.track('payment info entered', orderCompletedPayload);
    analytics?.track('order completed', orderCompletedPayload);

    if (tp.user.isUserValid()) {
      const subscriber = new Subscriber();
      await subscriber.init();
      subscriber.getIterableCampaign();
      const { email, iterableEmail, campaignId, templateId = null } = subscriber;

      if (iterableEmail && campaignId) {
        const iterableTracker = new IterableTracker(email);
        iterableTracker
          .trackCheckoutComplete(email, templateId, campaignId, conversion)
          .catch((error) => console.error(error));
      }
    }
  },
  checkoutCustomEvent: (event: CheckoutCustomEvent) => {
    const { analytics } = window;
    switch (event.eventName) {
      case 'term-changed':
        {
          const { termId } = event.params;
          if (termId && termId !== 'no change') {
            let removedProduct;
            if (sessionData.offerTerms && sessionData.offerTerms[termId]) {
              removedProduct = sessionData.offerTerms[termId];
            } else {
              removedProduct = {
                ...unsetTermProps,
                id: termId,
              };
            }
            const productRemovedPayload = {
              currency: removedProduct.currency,
              ...sessionData.identifiers,
              products: [removedProduct],
              value: removedProduct.price,
            };

            analytics?.track('product removed', productRemovedPayload);
          }
        }
        break;
      case 'payment-method-selected':
        if (sessionData.currentTerm) {
          const checkoutStartedPayload = {
            currency: sessionData.currentTerm.currency,
            ...sessionData.identifiers,
            products: [sessionData.currentTerm],
            value: sessionData.currentTerm.price,
          };

          analytics?.track('checkout started', checkoutStartedPayload);
        }
        break;
      default:
        break;
    }
  },
  checkoutSelectTerm: (termDetails: TermDetailsProps) => {
    const { termId } = termDetails;
    const { analytics } = window;
    let selectedProduct;
    if (sessionData.offerTerms && sessionData.offerTerms[termId]) {
      selectedProduct = sessionData.offerTerms[termId];
      sessionData.termUnset = false;
    } else {
      sessionData.termUnset = true;
      selectedProduct = {
        ...unsetTermProps,
        id: termId,
      };
    }

    sessionData.currentTerm = selectedProduct;
    const productAddedPayload = {
      currency: selectedProduct.currency,
      ...sessionData.identifiers,
      products: [selectedProduct],
      value: selectedProduct.price,
    };

    analytics?.track('product added', productAddedPayload);

    if (tp.user.isUserValid()) {
      const { email } = tp.pianoId.getUser();

      const iterableTracker = new IterableTracker(email);

      iterableTracker
        .trackStartCheckout(window.location.href, termDetails.termName)
        .catch((error) => console.error(error));
    }
  },
  checkoutStateChange: (event: CheckoutCustomEvent) => {
    const { analytics } = window;
    switch (event.stateName) {
      case 'auth': {
        const beginSignupPayload = {
          ...sessionData.identifiers,
        };

        analytics?.track('begin_sign_up', beginSignupPayload);
        sessionData.beganSignup = true;
        break;
      }
      default:
        break;
    }
  },
  customEvent: (event: CustomEvent) => {
    switch (event.eventName) {
      case 'passwordlessClose':
        window.location.reload();
        break;
      default:
        break;
    }
  },
  experienceExecute: (event: ExperienceEvent) => {
    const { countryCode } = event.result.events[0].eventExecutionContext;
    tp.pianoId.init({ stage: `countryCode-${countryCode}` });
    tp.setCustomVariable('countryCode', countryCode);
  },
  loginSuccess: async (loginData: LoginDataProps) => {
    const { analytics, pvid } = window;
    const { uid: pianoUID, email } = tp.pianoId.getUser();

    sessionData.identifiers.piano_user_id = pianoUID;

    const trackLogin = () => {
      if (sessionData.beganSignup) {
        const signedUpPayload = {
          ...sessionData.identifiers,
        };

        analytics?.track('sign up', signedUpPayload);
      }

      const trackingData = {
        eventAction: '',
        eventLabel: '',
      };

      if (loginData.registration) {
        geoIp(pianoUID);
        analytics?.track(
          'account.created',
          {
            email,
            pageview_id: pvid,
          },
          {
            context: {
              traits: {
                email,
                piano_user_id: pianoUID,
              },
            },
          },
        );
        trackingData.eventAction = 'create account';
        trackingData.eventLabel = 'account creation successful';
      } else {
        trackingData.eventAction = 'sign in';
        trackingData.eventLabel = 'sign in successful';
      }

      analytics?.identify({
        email,
        piano_user_id: pianoUID,
      });

      trackEvent({
        ...trackingData,
        eventCategory: 'account',
        userIDCD: sha256(email).toString(),
      });
    };
    const rid: string = process.env.PIANO_RID_DIGITAL || 'default';
    const accessResponse: AccessResponse = await new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      window.tp.api.callApi('/access/check', { rid }, (data: AccessResponse) => {
        if (data) {
          resolve(data);
        } else {
          reject(new Error('Check access failed'));
        }
      });
    });

    // Don't refresh experience if registration login, as this will prevent user from getting
    // Registration/Day Pass term through regwall. The required refresh will happen after the user closes the checkout
    // modal.
    const refreshExperience =
      !loginData.registration &&
      (accessResponse.access.granted || (tp.contentSection === 'F-Service' && loginData.source !== 'OFFER'));

    trackLogin();
    handleUserChange(refreshExperience);
  },
  manualCreditRedeemed: () => {
    tp.template.close();
    window.location.reload();
  },
  setResponseVariable: ({ responseVariables: { paywall30 } }: ResponseVariables) => {
    setPaywall30(paywall30);
  },
  showOffer: async (offerParams: OfferParams) => {
    const { analytics } = window;
    const user = tp.pianoId.getUser();

    sessionData.identifiers = {
      fuid: CookieService.get('fuid') || '',
      ga_client_id: CookieService.get('_ga')?.slice(6) || '',
      piano_user_id: user?.uid || '',
    };

    try {
      const response = await fetch(`/api/getPianoData/offerTerms/${offerParams.offerId}/`);
      if (response.ok) {
        const terms = (await response.json()) as OfferTerms;
        sessionData.offerTerms = terms;
      }

      const productViewedPayload = {
        ...sessionData.identifiers,
        products: [
          {
            offer_id: offerParams.offerId,
          },
        ],
      };
      analytics?.track('product viewed', productViewedPayload);
    } catch (error) {
      console.error(error);
    }
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((i) => {
      if (i.name.includes('offer')) {
        i.setAttribute('aria-label', 'subscribe offer');
      }
    });
  },
});

export default pianoHandlers;
