import styled from 'styled-components';
import { Theme, getColor, getBodyStyle } from 'styles/theme';
import { checkmark } from 'styles/icons';

export const CheckboxWrapper = styled.div<{ theme: Theme }>`
  .label {
    overflow: inherit; 
    display: flex;
    align-items: center;
  }

  .required {
    color: ${getColor('textAccentSecondary')};
  }

  &.disabled {
    .check-mark {
      border: 2px solid ${getColor('iconSecondary')};
    }
    .label {
      cursor: default;
    }
  }

  &.error {
    .check-mark {
      border: 2px solid ${getColor('semanticsError')};
    }

    .label {
      color: ${getColor('textAccentSecondary')};
    }
  }
`;

export const CheckboxInput = styled.input<{ theme: Theme }>`
  visibility: hidden;
  height: 0;
  width: 0;

  &:checked ~ .check-mark,  &:hover ~ .check-mark {
    &::after {
      content: "";
      ${checkmark}
      top: -1px;
      left: 3px;
    }
  }

  &:checked ~ .check-mark, &:active ~ .check-mark {
    background-color: ${getColor('surfaceBlack')};
  }
`;

export const Label = styled.label<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' }, 'regular')}
  line-height: 24px;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: ${getColor('textPrimary')};

  .required {
    margin-left: 8px;
  }
`;

export const Checkmark = styled.span<{ theme: Theme }>`
  display: inline-block;
  height: 16px;
  min-width: 16px;
  background-color: ${getColor('surfaceWhite')};
  border: 2px solid ${getColor('borderBlack')};
  border-radius: 2px;
  margin-right: 8px;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
  }
`;
