/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useRouter } from 'next/router';
import { trackEvent } from 'services/Gtm/functions';
import { EventData } from 'services/Gtm/entities';
import { ButtonSizeStyling } from 'components/Globals/Base/Button/types';
import { AnchorHTMLAttributes, DetailedHTMLProps, useEffect, useState } from 'react';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { Microsites } from 'constants/constants';
import { RedirectClickData } from 'interfaces/Analytics';
import { SegmentService } from 'services/Segment';
import CustomWindow from 'interfaces/Utils';
import { LinkTypes } from './types';
import * as S from './LinkStyles';

declare let window: CustomWindow;

export interface CustomLinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  dataCy?: string;
  trackingData?: EventData;
  // TODO: the ariaLabel prop can be removed and sent directly through aria-label
  ariaLabel?: string;
  type?: keyof typeof LinkTypes;
  size?: { default: keyof ButtonSizeStyling; [key: string]: keyof ButtonSizeStyling };
  center?: boolean;
  hasRightArrow?: boolean;
  hasLeftArrow?: boolean;
  onKeyDown?: () => void;
  onMouseEnter?: () => void;
  isRedirect?: boolean;
}

// make full reloads when changing a route, in order to disable SPA
export const changeRoute = (url: string) => {
  window.location.href = url;
  return Promise.resolve(true);
};

export const parseHref = (href?: string) => {
  const whitelistedEnvironments = ['dev', 'qa', 'staging', 'feature'];
  const currentEnvironment = process.env.ENVIRONMENT;

  // if the app is in development mode or if it's built for the remote dev and qa envs, preserve the href
  if (
    process.env.NODE_ENV === 'development' ||
    (currentEnvironment && whitelistedEnvironments.includes(currentEnvironment))
  ) {
    return href;
  }

  if (!href) {
    return '';
  }

  if (href.startsWith('/')) {
    return `https://fortune.com${href}`;
  }

  return href;
};

/**
 * Wrapper component around next/link that allows passing a className (which is often needed on links).
 * This component also handles tracking on clicks.
 */
const CustomLink = ({
  href,
  target = '_self',
  ariaLabel = '',
  children,
  className = '',
  dataCy,
  trackingData,
  type,
  size,
  center,
  hasRightArrow = true,
  hasLeftArrow = false,
  isRedirect = false,
  ...rest
}: CustomLinkProps) => {
  const parsedHref = parseHref(href);

  const handleClick = () => {
    if (trackEvent && trackingData) trackEvent(trackingData);
  };

  return (
    <S.Link
      $type={type || 'primary'}
      $size={size}
      $center={center}
      $hasRightArrow={hasRightArrow}
      $hasLeftArrow={hasLeftArrow}
      href={parsedHref}
      target={target}
      className={`${className} ${isRedirect ? 'redirect' : ''}`}
      data-cy={dataCy}
      onClick={handleClick}
      {...rest}
      {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      suppressHydrationWarning
    >
      {children}
    </S.Link>
  );
};

function stripWwwFromUrl(url: string): string {
  // Regular expression to match 'www.' after the protocol
  const wwwRegex = /^(https?:\/\/)?(www\.)/i;

  // Remove 'www.' if it exists
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return url.replace(wwwRegex, (match, protocol) => protocol || '');
}

function filterPage(path: string): boolean {
  /* @description - url we are tracking to test */
  /* 
    Example urls:
      https://oc.brcclx.com/t?lid=26687034&tid=[dynamic]post-id[/dynamic]
      https://www.cardratings.com/bestcards/featured-credit-cards?shnq=5048341&src=692824&var2=[dynamic]post-id[/dynamic]
      https://issa.sjv.io/c/5196544/919499/12162?tag.subid=[dynamic]post-id[/dynamic]
      https://secure.money.com/pr/zf80c9147822?s1=[dynamic]post-id[/dynamic]
      https://r.financebuzz.com/aff_c?offer_id=8560&aff_id=1940&source=[dynamic]post-id[/dynamic]
      https://api.fintelconnect.com/t/l/665f84d9e7749d001b74af21?acid=[dynamic]post-id[/dynamic]
      https://www.creditsaint.com/enter/00e903869a98b935088abef62f0184fd?subid=[dynamic]post-id[/dynamic]
      https://try.thecreditpros.com/partner-home/?PPCPN=844-546-8642&offerID=91&affID=3722&phone=&guid1=&utm_medium=affiliate&utm_source=PayPerSale&utm_campaign=AF-ShortFormLander_d&Affiliate_Sub_ID_1__c=&conaffid=&t=102636df9414d854a83b56e096e677&aff_click_id=&r=3785728&aff_sub2=102636df9414d854a83b56e096e677
      https://fiona.com/partner/fortune-bridge/savings?bridge_partner=sofi-savings-direct&tag.sub_id=[dynamic]post-id[/dynamic]
      https://getstarted.avocademy.com/pages/fortune?sca_ref=5967926.PLEEmkgmy8&utm_source=fortune&sca_source=[dynamic]post-id[/dynamic]
  */

  try {
    const { origin } = new URL(path);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useRouter();
    const currentPath = router.asPath;
    const redirectOriginsList = [
      'https://creditsaint.com',
      'https://fiona.com',
      'https://app2.earnin.com',
      'https://empower.me',
      'https://offers.evenfinancial.com',
      'https://cardratings.com',
      'https://nextinsure.com',
      'https://refer.sofi.com',
      'https://issa.sjv.io',
      'https://interactivebrokers.com',
      'https://r.financebuzz.com',
      'https://secure.money.com',
      'https://wealth-click-service.bankrate.com',
      'https://oc.brcclx.com',
      'https://bankrate.com/landing',
      'https://myfinance.com/reporting',
      'https://getstarted.avocademy.com',
      'https://bilt-rewards.sjv.io',
      'https://betterment.evyy.net',
      'https://nobledesktop.com',
      'https://api.fintelconnect.com',
      'https://funnel.naturaltracking.com',
      'https://apply.centuryss.com',
      'https://nationalpersonaltraininginstitute.sjv.io',
      'https://offers.engine.tech',
      'https://insurance.brite.co',
      'https://shareasale.com',
      'http://debtrelief.go2cloud.org',
      'https://ndrtracking.com',
      'https://offers.moneylion.com',
      'https://itrustcapital.com',
      'https://moneymanagement.org',
      'https://appwiki.nl',
      'http://businesscom.go2cloud.org',
      'https://scalahosting.com',
      'https://moneylion.com',
      'https://nextinsurance.sjv.io',
      'https://nextinsurance.com',
      'https://try.thecreditpros.com',
      'https://tracking.thecreditpros.com',
    ];
    const includedMicrositesList = [Microsites.Recommends, Microsites.Education];

    if (
      origin !== 'https://fortune.com' &&
      process.env.NEXT_PUBLIC_RECOMMENDS_REDIRECT_ENABLE === 'true' &&
      includedMicrositesList.some((substring) => currentPath.includes(substring)) &&
      redirectOriginsList.includes(stripWwwFromUrl(origin))
    ) {
      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
}

const generateUuid = () => uuidv4();

const Segment = SegmentService.getInstance();

const sendRedirectData = async (trackingData: RedirectClickData) => {
  const encodedData = JSON.stringify({ ...trackingData });
  Segment.trackClickEvent('click_action_redirect', 'redirect_link', trackingData.pvUUID, {
    ...trackingData,
  });
  const supabaseUrl = `/api/supabase?redirectUrl=${encodeURIComponent(
    trackingData.redirectUrl,
  )}&trackingData=${encodedData}`;
  await fetch(supabaseUrl, {
    headers: {
      'Content-Type': 'application/json',
      method: 'GET',
    },
  });
};

const CustomLinkWithRedirect = (props: CustomLinkProps) => {
  const { children, type, rel, target, className, dataCy, href } = props;
  const { data } = useAnalyticsData(href);

  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    /*
     * @description - TODO use url parser
     */
    const redirectUrl = `${window ? window.location.origin : ''}/recommends/redirect/?click-data=${JSON.stringify({
      ...data,
    })}&origin=${encodeURIComponent(href!)}`;
    setUrl(redirectUrl);
  }, [data, href]);

  return (
    <CustomLink
      onClick={async () => {
        if (!data) return;
        const clickISODate = new Date().toISOString();

        await sendRedirectData({ ...data, clickISODate, redirectUrl: url });
      }}
      isRedirect
      key={generateUuid()}
      href={url || (href as string)}
      type={type || 'primary'}
      target={target}
      rel={rel}
      className={`${className} styled-custom-redirect-link`}
      data-cy={dataCy}
      aria-disabled
    >
      {children}
    </CustomLink>
  );
};

const WithRedirect = (props: CustomLinkProps) => {
  const { children, href, className, type } = props;
  const willRedirect = filterPage(href!);

  if (willRedirect) {
    return (
      <CustomLinkWithRedirect
        href={href}
        {...props}
      />
    );
  }

  return (
    <CustomLink
      {...props}
      className={cx(className, { 'styled-custom-link': type })}
    >
      {children}
    </CustomLink>
  );
};

export default WithRedirect;
