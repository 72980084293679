import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const LeaderboardAdWrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('surfaceWhite')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;

  &:empty {
    min-height: 0;
    height: 0;
  }
  
  @media (${breakpoint.smMin}) {
    min-height: 110px!important;
  }

  @media (${breakpoint.mdMin}) {
    min-height: 112px!important;
  } 

  @media (${breakpoint.lgMin}) {
    min-height: 300px!important;
  }
`;

export const NativoLeaderboard = styled.div`
  min-height: 74px;

  @media (${breakpoint.mdMin}) {
    min-height: 114px;
  }
`;

export const LayoutWrapper = styled.div<{ theme: Theme; $isRecommends: boolean }>`
  // overwriting the old layout padding with the new design system values (only for recommends)
  ${({ $isRecommends }) =>
    ($isRecommends ?
      css`
        overflow: clip;
        // footer on Recommends takes up the whole screen width, margins should only apply to main content
        main {
          margin: auto;
          padding: 0 var(--Grid-Margin);

          @media (${breakpoint.xxlMin}) {
            max-width: 1440px;
          }
        }
      ` :
      css`
        margin: auto;
        overflow: clip;
        
        @media (${breakpoint.mdMin}) {
          padding: 0px 24px;
        }

        @media (${breakpoint.xlMin}) {
          padding: 0px 48px;
        }
        
        @media (${breakpoint.xxlMin}) {
          max-width: 1440px;
        }
      `)}
`;
