import { useContext, useEffect } from 'react';
import { UserContext } from 'providers/UserProvider';
import { AD_TAGS } from 'components/PaidPlacement';
import { NoConsent, Wrapper } from '../VodVideo/VodVideoStyles';

export interface StnVideoProps {
  dataCy?: string;
  videoId: string;
  adTags?: {
    adtagname: string;
    description: string;
    slug: string;
  }[];
}

const StnVideo = ({ videoId, adTags, dataCy = '' }: StnVideoProps) => {
  const { hasConsent } = useContext(UserContext);
  const updateStnCid = adTags?.find((tag) => tag.adtagname === AD_TAGS.NO_VIDEO_ADS);
  const updatedVideoId = updateStnCid ? videoId.replace(/-14497$/g, '-16481') : videoId;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://embed.sendtonews.com/player3/embedcode.js?SC=${updatedVideoId}`;
    script.async = true;
    script.type = 'text/javascript';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [updatedVideoId]);

  if (hasConsent === null) {
    return (
      <Wrapper>
        <NoConsent />
      </Wrapper>
    );
  }

  return (
    <div
      data-cy={dataCy}
      className={`stn-video-player s2nPlayer k-${updatedVideoId}`}
      data-type='float'
    />
  );
};

export default StnVideo;
