import { MenuItemProps } from 'interfaces/navigation/Header';
import useBreakpoint from 'hooks/useBreakpoint';
import cx from 'classnames';
import { COMMON_TEST_IDS } from 'constants/testsIds/common';
import * as S from './NavigationStyles';
import SubMenuV1 from './SubmenuV1';
import SubMenuV2 from './SubmenuV2';

interface NavItemProps extends MenuItemProps {
  currentUrl: string;
  color: string;
  subdomain: string;
  isSimple: boolean;
  showNewTopicNav: boolean;
}

const NavItem = ({ currentUrl, color, url, label, subMenu, subdomain, isSimple, showNewTopicNav }: NavItemProps) => {
  const mdMin = useBreakpoint('mdMin');
  const showSubmenu = mdMin && subMenu && subMenu?.length > 0;
  const showSubMenuV1 = !showNewTopicNav && showSubmenu;
  const showSubMenuV2 = showNewTopicNav && showSubmenu;
  const homepages = ['/', '/recommends/', '/crypto/', '/well/'];

  const isSelected = () => {
    if (homepages.includes(url)) {
      const pageIndex = currentUrl.indexOf('page');

      if (pageIndex !== -1) return currentUrl.slice(0, pageIndex) === url;

      return currentUrl === url;
    }
    return currentUrl.includes(url);
  };

  const trackingData = {
    contentPlacementCD: 'topic navigation',
    eventAction: 'topic navigation',
    eventCategory: 'navigation',
  };

  const dataCyLabel = label.toLowerCase().split(' ').join('-');

  return (
    <S.NavigationItem
      className={subdomain}
      $showNewTopicNav={showNewTopicNav}
      $color={color}
      $isSimple={isSimple}
    >
      <S.NavigationStyledLink
        $color={color}
        href={url}
        ariaLabel={`Go to ${label}`}
        className={cx(subdomain, {
          selected: isSelected(),
          showSubmenu,
        })}
        tabIndex={0}
        trackingData={{ ...trackingData, eventAction: `${trackingData.eventAction} - level 0`, eventLabel: label }}
        type='topicNavigation'
        $showNewTopicNav={showNewTopicNav}
        $isSimple={isSimple}
        data-cy={dataCyLabel}
      >
        {label}
      </S.NavigationStyledLink>

      {showSubMenuV1 && (
        <SubMenuV1
          subMenu={subMenu}
          parentLabel={label}
          trackingData={trackingData}
          dataCyLabel={`${COMMON_TEST_IDS.SUBMENU}-${dataCyLabel}`}
        />
      )}
      {showSubMenuV2 && (
        <SubMenuV2
          currentUrl={currentUrl}
          subMenu={subMenu}
          parentLabel={label}
          dataCyLabel={`${COMMON_TEST_IDS.SUBMENU}-${dataCyLabel}`}
          isSimple={isSimple}
        />
      )}
    </S.NavigationItem>
  );
};

export default NavItem;
