import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { blueSecondaryLink } from 'components/Globals/Base/Link/LinkStyles';
import { getColor, Theme } from 'styles/theme';

export const FooterWrapper = styled.footer<{ theme: Theme }>`
  border-top: 1px solid ${getColor('borderSecondary')};
  font-family: ${font.graphikCond};
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 12px;
  margin: 80px auto auto;
  max-width: ${breakpoint.xxlVal};
    
  @media (${breakpoint.mdMin}) {
      margin: 80px -24px auto;
      width: calc(100% + 48px);
  } 
    
  @media (${breakpoint.xlMin}) {
      margin: 80px -48px auto;
      width: calc(100% + 96px);
  }
`;

export const FooterNavigationWrapper = styled.nav`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(1, auto);

  @media (${breakpoint.mdMin}) {
    gap: 16px;
    grid-template-columns: repeat(3, auto);
    padding: 24px;
  }

  @media (${breakpoint.xxlMin}) {
    padding: 24px 48px;
  }
`;

export const FooterNavigationMenu = styled.div`
  display: flex;
`;

export const FooterNavigationSection = styled.div<{ theme: Theme }>`
  border-bottom: 1px solid ${getColor('borderSecondary')};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  
  &:last-child {
    border-bottom: 0;
  }
 
  @media (${breakpoint.mdMin}) {
    padding: 0;
    border-bottom: 0;
    border-right: 1px solid ${getColor('borderSecondary')}; 

    &:nth-child(1) {
      grid-column-end: 2;
    }

    &:nth-child(1),
    &:nth-child(2) {
      grid-column-start: span 2;
    }

    &:nth-child(3), 
    &:nth-child(4), 
    &:nth-child(5) {
      grid-row: 2;
    }
    
    &:last-child,
    &:nth-child(2)  {
      border-right: 0;
    }
  }

  @media (${breakpoint.xxlMin}) {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      grid-row: 1;
    }

    &:nth-child(2)  {
      border-right: 1px solid ${getColor('borderSecondary')};
      padding-right: 16px;
      grid-column-start: span 4;
    }
  }

  &.sections {
    ${FooterNavigationMenu} {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, auto);
      align-items: flex-start;

      @media (${breakpoint.mdMin}) {
        grid-template-columns: repeat(5, 20%);
      }

      @media (${breakpoint.lgMin}) {
        grid-template-columns: repeat(5, auto);
      }
    }
  }

  &.rankings {
    ${FooterNavigationMenu} {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: 1fr;

      @media (${breakpoint.mdMin}) {
        grid-template-columns: repeat(2, auto);
      }
    }
  }
`;

export const FooterNavigationHeading = styled.div<{ theme: Theme }>`
  color: ${getColor('textAccentSecondary')};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;

  @media (${breakpoint.mdMin}) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const FooterBottomWrapper = styled.div<{ theme: Theme }>`
  border-bottom: 1px solid ${getColor('borderSecondary')};
  border-top: 1px solid ${getColor('borderSecondary')};
  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  @media (${breakpoint.mdMin}) {
    padding: 24px;
  }

  @media (${breakpoint.xxlMin}) {
    flex-direction: row;
    padding: 24px 48px;
  }
`;

export const FooterDisclaimerWrapper = styled.div<{ theme: Theme }>`
  color: ${getColor('textSecondary')};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 18px;
  order: 2;

  a {
    ${blueSecondaryLink}
  }

  p {
    margin: 0px;
  }

  @media (${breakpoint.xxlMin}) {
    order: 1;
  }
`;
